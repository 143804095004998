import { Injectable } from '../../node_modules/@angular/core';
import { HttpClient } from '@angular/common/http';
import {Customers} from './models/customer.model'

@Injectable()
export class ServerService

{
    posts='';
    private  contacts:  Array<object> = [];
    constructor(private httpC: HttpClient,){}
    apiUrl='http://localhost/api';
    getData()
        {

        
        //  return this.http.get('http://localhost/api/values').subscribe((data:  Array<object>) => {
        //     this.contacts  =  data;
        //     console.log(data);
           
        // });

      //  return this.http.get('http://localhost/api/values').map(res => res.json());
      return this.httpC.get<Customers[]>('http://localhost/api/values');
           
       
    }
    postData(contact:any){
      // return  this.httpC.post('http://localhost/api/values/',JSON.stringify(contact)).map(res => res.json());
         // return  this.httpC.post(`${this.apiUrl}/values/`,contact);
         return  this.httpC.post('http://api.easio.in/api/values/',contact);
    }
     
    // getData() {
    //     this.http.get('http://localhost/api/ok').subscribe(res => {
    //       this.posts = res.toString();
    //       return this.posts;
    //     });
    //   }
}
