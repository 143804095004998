export class Customers 

{

    id: number;
    Name: string;
    age: number;
    adress: string;
    phone: string;
    city: string;
}