import { Component } from '@angular/core';
import { NgModule } from '@angular/core';
import { variable } from '../../node_modules/@angular/compiler/src/output/output_ast';
import {ServerService} from './Server.service';
import {Customers} from './models/customer.model'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'App1 hello bjhjh bumb';
    data = 'Hello';
    name = '';
    x:number  = 25;
    y:number = 30;
    z:number = this.x+ this.y;
    AgeT:number;
    sData ='';
    sData2:Customers[];
    private  contacts:  Array<object> = [];
    constructor (private serverS:ServerService){}
    // getit(){

    //   this.serverS.getData().subscribe(
    //     response=>console.log(response),
    //     (error)=>console.log(error),
    //   );}
  // Get DATA from API
    getit() {
        // this.serverS.getData().subscribe(res => {
        //          this.sData = res.toString();
        //   //       return this.posts;)
        //   this.serverS.getData
        // });

        this.serverS.getData().subscribe((data:  Array<object>) => {
          this.contacts  =  data;
          console.log(data);
        })
      }

      //====== POST DATA TO API

      sendPost()
      {
           var Dlist={
   Name:this.name,
   age: this.AgeT,
   Phone:'244566',
   adress:'Dalagte'

           };

  this.serverS.postData(Dlist).subscribe((response=>{
     console.log(response),
     this.sData=response.toString()

  }));

      }

    }
   

